<template>
  <div class="page">
    <van-loading v-if="state.loading" class="mt-5" color="#1989fa" size="36" vertical>
      {{ state.msg }}
    </van-loading>
    <van-empty v-else image="error" :description="state.msg" />
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { useRoute } from "vue-router";
import { Toast, Empty, Image as VanImage } from "vant";
import { onMounted, reactive } from "@vue/runtime-core";
import { isWechat, isAlipay } from "@/utils/util";
import { setDocumentTitle } from "@/utils/domUtil";
import { planScanpay } from "@/api/checkout.service"
import { signaturePack } from "@/api/wechat.service";

export default {
  components: {
    [Empty.name]: Empty,
    [VanImage.name]: VanImage,
  },
  setup() {
    const state = reactive({
      // 没有报错时, loading 一直是 true
      loading: true,
      msg: "请稍后",
      signaturePageStep: 'wait',  // wait待执行, success成功(仅此状态可以发起微信支付), failed失败
    });

    const showErrorMessage = (msg) => {
      state.loading = false;
      state.msg = msg;
    }

    const route = useRoute();
    const { order_type, trade_no } = route.query;
    if (!order_type || !trade_no) {
      showErrorMessage("缺少参数, 请重新扫码");
    }

    // 默认使用支付宝H5
    let client = 'alipay';
    if (isWechat()) {
      client = 'weixin';
    } else if (isAlipay()) {
      client = 'alipay';
    }
    if (!client) {
      showErrorMessage("请使用微信或支付宝扫码");
    }

    const signaturePage = async () => {
      try {
        const url = /(Android)/i.test(navigator.userAgent)
          ? location.href.split("#")[0]
          : window.entryUrl;
        const { result } = await signaturePack(url);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "closeWindow", "chooseWXPay"],
        });
        wx.ready(() => {
          state.signaturePageStep = "success";
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          state.signaturePageStep = "failed";
          showErrorMessage(res.errMsg);
        });
      } catch (error) {
        state.signaturePageStep = "failed";
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        showErrorMessage(msg ? msg : "前端脚本异常/页面签名失败");
        if (!msg) console.log(error);
      }
    };

    // 付费计划订单扫码支付
    const handlePlanPay = async () => {
      try {
        const { result } = await planScanpay({
          trade_no, client
        });

        // 支付宝
        if (client == "alipay") {
          const div = document.createElement("alipayForm");
          div.innerHTML = result.form;
          document.body.appendChild(div);
          setTimeout(() => {
            document.getElementById("alipay_submit").submit(); // <form id="alipay_submit">
          }, 1500);
          return;
        }

        // 微信支付
        if (client == "weixin") {
          if (result.fulfil_status == "fulfiled") {
            return showErrorMessage(result.msg);
          }

          // H5支付直接跳转
          if (result.mweb_url) {
            window.location.href = result.mweb_url;
            return;
          }

          // 公众号JSAPI支付
          wx.chooseWXPay({
            timestamp: result.timeStamp,
            nonceStr: result.nonceStr,
            package: result.package,
            signType: result.signType,
            paySign: result.paySign,
            success: () => {
              Toast.success({
                message: "支付成功",
                forbidClick: true,
                overlay: true,
                duration: 0
              });
              setTimeout(() => {
                wx.closeWindow();
              }, 5000);
            },
            error: (res) => {
              showErrorMessage(res.errMsg ? res.errMsg : "支付失败");
            },
            cancel: () => {
              showErrorMessage("您已取消支付");
            },
          });
        }
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        console.log(msg ? msg : "前端脚本异常");
        state.loading = false;
        state.msg = msg;
      }
    }

    onMounted(async () => {
      setDocumentTitle('收银台');
      if (client == "weixin") {
        signaturePage();
      }
      if (state.loading) {
        switch (order_type) {
          case "plan":
            if (client == "weixin") {
              // @todo 微信页面签名完成才能执行唤起微信支付操作, 优化成 Promise 操作
              const timer = setInterval(() => {
                if (state.signaturePageStep == 'wait') {
                  return;
                }
                if (state.signaturePageStep == 'success' && state.loading) {
                  handlePlanPay();
                  clearInterval(timer);
                }
                if (state.signaturePageStep == 'failed') {
                  clearInterval(timer);
                }
              }, 1000);
            } else {
              handlePlanPay();
            }
            break;

          default:
            state.loading = false;
            state.msg = "未知的订单类型";
            break;
        }
      }
    });

    return { state };
  },
};
</script>
